import {initInView} from "./helpers";

document.querySelectorAll('[data-js="carousel-specials-daily"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/carousel/carousel-specials-daily' /* webpackChunkName: "/dist/js/carousel-specials-daily" */).then(({default: init}) => init($el));
    });
});
//
// document.querySelectorAll('.js-section-info').forEach($el => {
//     initInView($el).then(() => {
//         import('../../../partials/blocks/section-info/section-info' /* webpackChunkName: "/dist/js/section-info" */).then(({default: init}) => init($el));
//     });
// });
//


document.querySelectorAll('[data-layout="header"]').forEach($el => {
    import('../../../partials/layout/header/header' /* webpackChunkName: "/dist/js/header" */).then(({default: init}) => init($el));
});

document.querySelectorAll('[data-block="menu-mobile"]').forEach($el => {
    import('../../../partials/layout/menu-mobile/menu-mobile' /* webpackChunkName: "/dist/js/menu-mobile" */).then(({default: init}) => init($el));
});

document.querySelectorAll('[data-block="slider"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/slider/slider' /* webpackChunkName: "/dist/js/slider" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="form-club-card"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/form-club-card/form-club-card' /* webpackChunkName: "/dist/js/form-club-card" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="form-concierge-card"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/form-concierge-card/form-concierge-card' /* webpackChunkName: "/dist/js/form-concierge-card" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="form-gift-card"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/form-gift-card/form-gift-card' /* webpackChunkName: "/dist/js/form-gift-card" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="gallery-grid"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/gallery-grid/gallery-grid' /* webpackChunkName: "/dist/js/gallery-grid" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="gallery-masonry"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/gallery-masonry/gallery-masonry' /* webpackChunkName: "/dist/js/gallery-masonry" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="video-background-youtube"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/video-background-youtube/video-background-youtube' /* webpackChunkName: "/dist/js/video-background-youtube" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="google-map"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/google-map/google-map' /* webpackChunkName: "/dist/js/google-map" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="openstreet-map"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/openstreet-map/openstreet-map' /* webpackChunkName: "/dist/js/openstreet-map" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="filter-restaurants"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/filter-restaurants/filter-restaurants' /* webpackChunkName: "/dist/js/filter-restaurants" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="filter-specials"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/filter-specials/filter-specials' /* webpackChunkName: "/dist/js/filter-specials" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-request="onShowPopup"], .js-popup-trigger, [data-fancybox]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/popup/popup' /* webpackChunkName: "/dist/js/popup" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="restaurant-picker"] select').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/restaurant-picker/restaurant-picker' /* webpackChunkName: "/dist/js/restaurant-picker" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="activity-picker"] select').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/activity-picker/activity-picker' /* webpackChunkName: "/dist/js/activity-picker" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="field-select-nice"] select').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/field-select-nice/field-select-nice' /* webpackChunkName: "/dist/js/field-select-nice" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="tabs"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/tabs/tabs' /* webpackChunkName: "/dist/js/tabs" */).then(({default: init}) => init($el));
        import('../../../partials/blocks/tabs/tabs-specials-weekly' /* webpackChunkName: "/dist/js/tabs-specials-weekly" */).then(({default: init}) => init($el));
    });
});

if (document.querySelector('.js-welcome-popup')) {
    import('../../../partials/blocks/popup/popup' /* webpackChunkName: "/dist/js/popup" */).then(({default: init}) => init());
}

document.querySelectorAll('[data-block="book-now"]').forEach($el => {
    import('../../../partials/blocks/book-now/book-now' /* webpackChunkName: "/dist/js/book-now" */).then(({default: init}) => init($el));
});

document.querySelectorAll('.js-loader').forEach($el => {
    import('../../../partials/blocks/loader/loader' /* webpackChunkName: "/dist/js/loader" */).then(({default: init}) => init($el));
});

if (window.location.pathname === '/specials') {
    import('../../../partials/blocks/popup/popup' /* webpackChunkName: "/dist/js/popup" */).then(({default: init}) => init());
    import('../../../partials/blocks/popup/popup-special' /* webpackChunkName: "/dist/js/popup-special" */).then(({default: init}) => init());
}
